import { ChakraProvider } from '@chakra-ui/react';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import * as React from 'react';
import theme from '../config/theme';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../utils/apollo';
import { AppProps } from 'next/dist/pages/_app';
import { IS_SERVER } from '../config/constants';
import { mergeWith as merge } from '@chakra-ui/utils';
import StoreProvider from '../store/provider';
import { TvvCoreBackendConfig } from '../store/store';
import AdminBar from '../components/Common/AdminBar';
import log from '../utils/logger';
import getAppConfig from '../utils/getAppConfig';
import 'nprogress/nprogress.css';
import '../theme/fonts.css';
import { useEffect } from 'react';
import { FilterInputGrouped } from '../generated/types';
import Script from 'next/script';
import { GApageview } from '../utils/gtag';
import Head from 'next/head';
import '../theme/global.css';

const appConfig = getAppConfig();

if (!IS_SERVER && !appConfig.config.cookieScriptID) {
  (window as any).klaroConfig = require('../config/klaro').default;
  require('klaro').default;
}

let loadStart = 0;

Router.events.on('routeChangeStart', () => {
  loadStart = Date.now();
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => {
  const w = window as any;
  if (w) {
    // w._paq.push(['setReferrerUrl', w.location.href]);
    // w._paq.push(['setCustomUrl', url]);
    w._paq.push(['setDocumentTitle', window.document.title]);

    w._paq.push(['deleteCustomVariables', 'page']);
    const duration = loadStart ? (Date.now() - loadStart) / 1000 : 0;
    // w._paq.push(['setGenerationTimeMs', duration]);
    w._paq.push(['enableLinkTracking']);
    w._paq.push(['trackPageView']);
    w._paq.push(['trackVisibleContentImpressions']);
  }

  NProgress.done();
});
Router.events.on('routeChangeError', () => NProgress.done());

export type TvvCoreAppProps = AppProps & {
  err:
    | (Error & {
        statusCode?: number;
      })
    | null;
};

export interface GlobalStyleVars {
  progressColor?: string;
}

export interface TvvCoreAppConfig {
  gaId?: string;
  cookieScriptID?: string;
  adsense?: {
    id: '8990558636310587';
  };
  newLayout?: {
    footer?: {
      addons?: 'waves' | 'tour';
    };
    cta?: {
      activityTeaser?: string;
      hotelTeaser?: string;
      newsTeaser?: string;
    };
  };
  queryVariables?: {
    hotel?: {
      activitiesInRegion: FilterInputGrouped;
    };
  };
  filters?: {
    groupSearch?: boolean;
  };
  usesNewLayout: boolean;
  fullUrl: string;
  siteName: string;
  frontendId: string;
  frontendName: string;
  menuMain: string;
  menuFooter: string;
  menuFooterMain?: string;
  embedEnabled: boolean;
  matomoId: string;
  globalStyleVars?: GlobalStyleVars;
  styleConfig?: {
    googleFonts?: string[];
  };
  renderConfig: {
    activity: {
      nearHotels: boolean;
      nearActivities: boolean;
      nearPois: boolean;
      hotelActivities: boolean;
      hotelActivitiesOther: boolean;
      renderSidebarAdTeaser: boolean;
    };
    hotel: {
      nearActivities: boolean;
      nearPois: boolean;
      renderSidebarAdTeaser: boolean;
    };
    poi: {
      nearHotels: boolean;
      nearActivities: boolean;
      nearPois: boolean;
      renderSidebarAdTeaser: boolean;
    };
    freizeithotelsTeaser: boolean;
  };
  titles?: {
    hotel?: {
      activities?: string;
      furterActivities?: string;
    };
    activity?: {
      hotel?: string;
    };
  };
}

export default function App({ Component, pageProps, err }: TvvCoreAppProps) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  let backendConfig: TvvCoreBackendConfig = {
    defaultActivityImage: null,
  };
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (appConfig.config.gaId) {
        GApageview(url, appConfig.config.gaId);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, appConfig]);

  // Check if we have backend config
  if (pageProps && pageProps.data) {
    const keys = ['route', 'search', 'searchByCategory'];

    for (const key of keys) {
      if (pageProps.data[key]) {
        if (pageProps.data.config) {
          const config = pageProps.data.config as any;

          backendConfig = {
            defaultActivityImage: config.defaultActivityImage,
          };
        }
      }
    }
  }

  const mergedTheme = merge(theme, appConfig.theme);

  if (!IS_SERVER) {
    log('_app PageProps', pageProps);
    log('_app AppConfig', appConfig);
    log('_app Theme', mergedTheme);
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Head>

      {appConfig.config.adsense?.id && (
        <>
          <Script
            crossOrigin="anonymous"
            src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-${appConfig.config.adsense.id}`}
            async
            strategy="afterInteractive"
          />
          <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `try{
            (adsbygoogle = window.adsbygoogle || []).push({});
          } catch(err){}`,
            }}
          />
        </>
      )}

      {/* {appConfig.config.cookieScriptID && (
        <Script
          src={`https://cdn.cookie-script.com/s/${appConfig.config.cookieScriptID}.js`}
          strategy="afterInteractive"
        />
      )} */}

      {appConfig.config.gaId && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${appConfig.config.gaId}`}
            strategy="afterInteractive"
          />
          <Script
            id="gtag"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied'});
            gtag('set', 'ads_data_redaction', true);
                
            gtag('config', '${appConfig.config.gaId}', {
              page_path: window.location.pathname,
            });
          `,
            }}
          />
        </>
      )}

      <ApolloProvider client={apolloClient}>
        <ChakraProvider resetCSS theme={mergedTheme}>
          <StoreProvider
            initialStoreData={{}}
            config={appConfig.config}
            backendConfig={backendConfig}
          >
            <Component {...pageProps} err={err} />

            <AdminBar />
          </StoreProvider>
        </ChakraProvider>
      </ApolloProvider>
    </>
  );
}
